import React from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useApi, methods } from '../../Hooks/useApi';

const ExportModal = ({
  reportType,
  open,
  onClose,
  exportURL,
  title,
  description,
  buttonText,
  fileName,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { executeApiCall } = useApi();

  const confirm = async () => {
    const payload = { reportType: reportType };
    try {
      await executeApiCall(
        exportURL,
        methods.post,
        payload,
        null,
        false,
        true,
        fileName
      );
      onClose();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik initialValues={{}} onSubmit={confirm}>
        {({ handleSubmit, isSubmitting }) => (
          <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={isSubmitting}
                onClick={handleSubmit}
                color="primary"
              >
                {buttonText}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ExportModal;
