// this returns true if found, and false otherwise
const regexvalidation = (value, validationType) => {
  const emailRegex =
    /[^\:\@\/\b\n\s]+(\.[^\:\@\/\b\n\s]+)*(\+([^\:\@\/\b\n\s]+\.)*[^\:\@\/\b\n\s]+)*\@[^\:\@\/\b\n\s]+(\.[^\:@\/\b\n\s]+)*\.[^\:\@\/\b\n\s]{2,}/i;
  const urlRegex =
    /((https?:\/\/)?(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(:\d{1,5})?((\/)([\w\.~%-]+))*(\/)?((\?)(([\w]+)(=[\w\.~%-]+)?((&)([\w]+)(=[\w\.~%-]+)?)*)?)?(#((([\w]+(=[\w\,\*\.~%-]+)?)(&[\w]+(=[\w\,\*\.~%-]+)?)*)|(:~:text=([\w\.~%-]+\,)?([\w\.~%-]+)(\,[\w\.~%-]+){0,2}))?)?)/i;
  const ipv6Regex =
    /(https?\:\/\/)?(((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])))|\[((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])))\])(\:(80|443))?\/?([^\/\s]+\/?)*/i;

  if (validationType === 'link') {
    // todo: use both url-regex and ipv6-regex (when the ipv6-regex works)
    return !urlRegex.test(value);
  }
  if (validationType === 'url') {
    return !urlRegex.test(value);
  }
  if (validationType === 'email') {
    return !emailRegex.test(value);
  }
  if (validationType === 'ipv6') {
    return !ipv6Regex.test(value);
  }
  if (validationType === 'urlonly') {
    let result = value.replace(emailRegex, '');
    return !urlRegex.test(result);
  }

  return false;
};

export default regexvalidation;

export const multipleDecimalsRegex = /^(-?\d+(\.\d)?)?$/;
